<template>
  <div>
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onLaunchEditor" />
  </div>
</template>

<script>
export default {
  methods: {
    onLaunchEditor () {
      this.params.context.componentParent.launchEditor(this.params.data) 
    },
  }
}
</script>
