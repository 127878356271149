<template>
  <div>
    <div class="flex flex-wrap items-center">
      <span>{{this.params.data.uniqueRef}}</span>
      <feather-icon title="Copy to clipboard" icon="ClipboardIcon" svgClasses="h-5 w-5 ml-1 hover:text-primary cursor-pointer" @click="onCopyToClipboard" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onCopyToClipboard () {
      navigator.clipboard.writeText(this.params.data.uniqueRef);
      this.$_notifySuccess('Copied to clipboard')
    },
  }
}
</script>
