<template>
  <travio-modal width='450' height="300" zIndex="999999">
    <h3 slot="header">Edit Priority</h3>
    <div slot="body">
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="">Priority (500 or over will show recommended tag on results)</label>
          <vs-input class="w-full" v-model.number="priority" type="number" min="0" max="999" v-validate="'required|min_value:0|max_value:999'" name="Priority" />
          <span class="text-danger text-sm">{{ errors.first('Priority') }}</span>
        </div>
        <div class="vx-col w-full mt-4">
          <label class="">Optional Features (comma-delimited list of feature tags)</label>
          <vs-textarea 
            name="Optional Features"
            rows="6" 
            v-model="features"
            counter="800"
            maxlength="800"
            v-validate="'max:800'"
            class="w-full mb-0"/>
          <span class="text-danger text-sm">{{ errors.first('Optional Features') }}</span>
        </div>
      </div>
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="onCancel" type="filled">Cancel</vs-button>      
      <vs-button class="ml-2" color="primary" @click="onUpdate" type="filled">Update</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
export default {
  components: {
    TravioModal
  },
  props: {
    data: { required: true },
  },
  data () {
    return {
      priority: 0,
      features: null,
    }
  },
  mounted () {
    this.priority = this.data.priority
    this.features = this.data.features
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    async onUpdate () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
    
      this.$emit('onUpdate', { id: this.data.id, priority: this.priority, features: this.features })
    },
    onCancel () {
      this.$emit('onCancel')
    },
  }
}
</script>
